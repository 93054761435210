body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary: rgba(238, 96, 38, 0.9);
  --hover: rgba(238, 96, 38, 1);
  --secondary: #faf9fa;
  --secondary-hover: #d4d3d4;
  --black: #000000;
  --white: #ffff;
  --red: rgba(255, 0, 0, 0.9);
  --red-hover: rgba(255, 0, 0, 1);
}

.cursor-pointer {
  cursor: pointer;
}

.form-control {
  border-radius: 10px !important;
  height: 48px;
}

.form-control:focus {
  border-color: var(--primary) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(238, 95, 38, 0.6) !important;
}

.arrow-select {
  position: absolute;
  right: 10px;
  top: 12px;
}

.color-grey {
  color: gray;
}

.offcanvas-end {
  width: 81% !important;
}

.btn:focus,
.btn:active {
  border-color: var(--primary) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(238, 95, 38, 0.6) !important;
}

.btn-primary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  border-radius: 10px !important;
  transition: all 0.25s ease-in-out;
  height: 48px;
}

.btn-primary:hover {
  background-color: var(--hover) !important;
  border-color: var(--hover) !important;
}

.btn-success {
  /* background-color: var(--primary) !important;
  border-color: var(--primary) !important; */
  border-radius: 10px !important;
  /* transition: all 0.25s ease-in-out; */
  height: 48px;
}

.btn-success:hover {
  /* background-color: var(--hover) !important;
  border-color: var(--hover) !important; */
}

.btn-secondary {
  border-color: var(--primary) !important;
  background-color: var(--secondary) !important;
  color: var(--hover) !important;
  font-size: 14px !important;
  border-radius: 10px !important;
}

.btn-secondary:hover {
  border-color: var(--secondary-hover) !important;
  background-color: var(--primary) !important;
  color: var(--white) !important;
}

label {
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}

.h-48 {
  height: 48px !important;
}

.react-tel-input .selected-flag {
  width: 48px !important;
  padding: 0 0 0 12px !important;
}

.react-tel-input .form-control {
  padding-left: 56px !important;
}

.toast {
  position: fixed !important;
  bottom: 3%;
  left: 36%;
  z-index: 1051;
  background-color: var(--primary) !important;
  color: var(--hover) !important;
}

/* Sidebar */

.sidebar {
  height: 100vh !important;
}

.pro-sidebar {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  height: 100%;
  position: fixed !important;
  left: 0 !important;
}

.pro-sidebar-header {
  padding: 20px 0px;
  display: flex;
  justify-content: center;
}

.pro-sidebar-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
}

.pro-sidebar-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 233px;
}

.pro-sidebar-inner {
  background-color: #ffff !important;
}

.sidebar-logo {
  cursor: pointer;
  width: 120px;
  height: auto;
  object-fit: contain;
}

.sidebar-logo-small {
  cursor: pointer;
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.sidebar-customer-card {
  box-shadow: 0 20px 30px 0 rgb(0 0 0 / 6%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  margin-left: 15px;
  margin-right: 15px;
  width: 80%;
  white-space: nowrap;

  position: relative;
}

.sidebar-close-customer {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: gray;
}

.sidebar-close-customer:hover {
  color: var(--primary);
}

.sidebar-customer-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #6c757d;
}

.sidebar-officer-img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.sidebar-customer-name {
  font-weight: bold;
  font-size: 14px;
  color: black;
}

.sidebar-customer-info {
  font-size: 12px;
  color: gray;
}

.sidebar-footer-txt {
  font-size: 12px;
  text-align: center;
  padding-bottom: 10px;
}

.sidebar-menu {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 80%;
  border-radius: 10px;
  padding: 10px 10px;
  cursor: pointer;
  color: var(--black);
  font-weight: 600;
  white-space: nowrap;
}

.sidebar-menu:hover {
  background-color: var(--primary);
  color: var(--white);
}

.sidebar-menu.active {
  background-color: var(--primary);
  color: var(--white);
}

/* ModalLoading */
.loading-modal .modal-content {
  background-color: rgba(0, 0, 0, 0) !important;
  z-index: 99;
  border: 0 !important;
  position: relative;
  opacity: 1;
  border: 1px solid #999;
  border-radius: 50px;
  outline: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* Login */
.login-container {
  background-image: url("https://main.mobile.doss.co.id/storage/uploads/2022/03/bg-yellow-img.webp");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.card-default {
  background-color: #ffff;
  border-radius: 20px;
  padding: 25px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.login-card {
  width: 500px;
  height: auto;
}

.header-h1 {
  font-size: 24px;
  font-weight: bold;
}

.header-h5 {
  font-size: 16px;
  font-weight: bold;
  color: gray;
}

.logo-doss-login {
  width: 35px;
  height: 35px;
  object-fit: contain;
}

/* Home */
.main-container {
  width: 100%;
  display: flex;
  align-items: stretch;
  position: relative;
}

/* TopNavbar */
.top-navbar-container {
  width: 100%;
  height: 80px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-navbar-input {
  position: relative;
  height: 48px;
}

.top-navbar-input input {
  padding-left: 35px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--secondary);
  border-color: transparent;
  width: 300px;
}

.top-navbar-search-by {
  font-size: 13px;
  color: gray;

  display: flex;
  align-items: center;
}

.top-navbar-search-by label {
  font-size: 13px;
}

.top-navbar-btn {
  position: absolute;
  right: 0;
  top: 0;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.top-navbar-collapse {
  cursor: pointer;
  color: var(--black);
}

.top-navbar-collapse:hover {
  cursor: pointer;
  color: var(--primary);
}

.top-navbar-icon {
  position: absolute;
  left: 12px;
  top: 16px;
}

.top-navbar-user {
  cursor: pointer;
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid gray;
  background-color: #ffff;
}

.top-navbar-name {
  font-size: 16px;
  font-weight: bold;
  text-align: right;
}

.top-navbar-email {
  font-size: 12px;
  color: gray;
  text-align: right;
}

/* Products */
.product-grid-container {
  display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(auto-fill, 195px);
  grid-gap: 1rem;
  justify-content: flex-start;
  align-content: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -ms-flex-line-pack: start;
}

.product-card {
  position: relative;
  background-color: var(--white);
  box-shadow: 0 20px 30px 0 rgb(0 0 0 / 6%);
  min-width: 195px;
  max-width: 195px;
  min-height: 300px;
  max-height: 300px;
  border-radius: 20px;
}

.product-content {
  padding: 15px 20px;
}

.product-card img {
  width: 100%;
  height: auto;
  max-height: 135px;
  object-fit: contain;
  cursor: pointer;
}

.product-id-txt {
  font-size: 14px;
  font-weight: bold;
}

.product-stock {
  color: var(--white);
  color: #4aca95;
  background-color: #cdfcdf;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  padding: 3px 10px;
}

.product-stock-gray {
  color: #ffff;
  background-color: #808080;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  padding: 3px 10px;
}

.product-name-txt {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.product-price-txt {
  color: var(--primary);
  font-weight: 600;
}

.product-add-icon {
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: var(--primary);
  box-shadow: 0 20px 30px 0 rgb(0 0 0 / 6%);
}

.product-add-icon {
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: var(--hover);
  box-shadow: 0 20px 30px 0 rgb(0 0 0 / 6%);
}

.product-add-to-cart {
  width: 100%;
  background-color: var(--white);
  color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #ee6026;
}

/* Customers */
.customer-add-btn {
  font-weight: bold !important;
  border-radius: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-size: 14px !important;
}

/* CustomerSearch */
.customer-search-card {
  box-shadow: 0 20px 30px 0 rgb(0 0 0 / 6%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  margin-left: 15px;
  margin-right: 15px;
  width: 80%;
  min-height: 500px;
}

.customer-search-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  /* border: 1px solid #6c757d; */
  text-align: center;
  overflow: hidden;
}

.customer-search-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.officer-search-img {
  width: 200px;
  height: auto;
  object-fit: contain;
}

.customer-search-name {
  font-weight: bold;
  font-size: 200%;
  color: black;
  text-align: center;
}

.officer-search-name {
  font-weight: bold;
  font-size: 120%;
  color: black;
  text-align: center;
}

.customer-search-info {
  font-size: 120%;
  color: gray;
  text-align: center;
}

.verified-txt {
  background-color: #cdfcdf;
  color: #3ec690;
  border-radius: 20px;
  font-size: 80%;
  padding: 5px 20px;
}

/* CartPopup */
.cart-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  cursor: pointer;
  position: fixed;
  right: 6%;
  bottom: 4%;
  box-shadow: 0 2px 6px 0 var(--N150, #b5bbc5);
  border-radius: 50%;
  transition: all 0.2s ease-in-out 0s;
  z-index: 3;
  background: var(--primary);
  font-size: 120%;
  font-weight: 600;
  color: var(--white);
}

.cart-popup:hover {
  background-color: var(--hover);
}

.cart-popup-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.popup-badge {
  position: absolute;
  right: -10px;
  top: 0;
  background-color: red;
  padding: 5px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

/* TaxSidebar */
.tax-sidebar-item {
  width: 100%;
  min-height: 50%;
  max-height: 66%;
  overflow-y: auto;
  padding: 10px;
}

/* CartSidebar */
.cart-sidebar-item {
  width: 100%;
  min-height: 50%;
  max-height: 53%;
  overflow-y: auto;
  padding: 10px;
}

@media (max-width: 1920px) {
  .cart-sidebar-item {
    width: 100%;
    min-height: 50%;
    max-height: 53%;
    overflow-y: auto;
    padding: 10px;
  }

  .tax-sidebar-item {
    width: 100%;
    min-height: 50%;
    max-height: 66%;
    overflow-y: auto;
    padding: 10px;
  }
}

@media (max-width: 1366px) {
  .cart-sidebar-item {
    width: 100%;
    min-height: 50%;
    max-height: 57%;
    overflow-y: auto;
    padding: 10px;
  }

  .tax-sidebar-item {
    width: 100%;
    min-height: 50%;
    max-height: 100%;
    overflow-y: auto;
    padding: 10px;
  }
}

@media (max-width: 1280px) {
  .cart-sidebar-item {
    width: 100%;
    min-height: 50%;
    max-height: 55%;
    overflow-y: auto;
    padding: 10px;
  }

  .tax-sidebar-item {
    width: 100%;
    min-height: 50%;
    max-height: 100%;
    overflow-y: auto;
    padding: 10px;
  }
}

@media (max-width: 640px) {
  .cart-sidebar-item {
    width: 100%;
    min-height: 36%;
    max-height: 38%;
    overflow-y: auto;
    padding: 10px;
  }

  .tax-sidebar-item {
    width: 100%;
    min-height: 50%;
    max-height: 100%;
    overflow-y: auto;
    padding: 10px;
  }
}

.cart-sidebar-subtitle {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.cart-sidebar-total-card {
  background-color: #ffff;
  border: 1px solid #0c6efd;
  color: #0c6efd;
  padding: 5px;
  border-radius: 5px;
}

.cart-sidebar-total-card-2 {
  background-color: #ffff;
  border: 1px solid #198753;
  color: #198753;
  padding: 5px;
  border-radius: 5px;
}

.cart-sidebar-total-card-3 {
  background-color: #ffff;
  border: 1px solid #dc3444;
  color: #dc3444;
  padding: 5px;
  border-radius: 5px;
}

.cart-sidebar-total-card-4 {
  background-color: #ffff;
  border: 1px solid #6c757d;
  color: #6c757d;
  padding: 5px;
  border-radius: 5px;
}

.cart-sidebar-card {
  padding: 10px;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0 20px 30px 0 rgb(0 0 0 / 6%);
}

.cart-sidebar-inner {
  display: flex;
  align-items: center;
  position: relative;
}

.cart-sidebar-card img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.cart-sidebar-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;

  font-weight: bold;
}

.cart-sidebar-title-2 {
  font-size: 14px;
  color: gray;

  font-weight: bold;
}

.cart-sidebar-black {
  font-size: 14px;
  color: black;
}

.cart-sidebar-price {
  font-weight: bold;
  color: red;
  font-size: 14px;
}

.cart-sidebar-price span {
  color: gray;
  font-size: 14px;
}

.cart-sidebar-qty {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  right: 5px;
  bottom: 5px;
}

.cart-sidebar-icon-add {
  cursor: pointer;
  /* color: var(--primary); */
  box-shadow: 0 20px 30px 0 rgb(0 0 0 / 6%);
}

.cart-sidebar-icon-add:hover {
  color: var(--hover);
}

.cart-sidebar-icon-remove {
  cursor: pointer;
  color: var(--red);
  box-shadow: 0 20px 30px 0 rgb(0 0 0 / 6%);
}

.cart-sidebar-icon-remove:hover {
  color: var(--red-hover);
}

.cart-sidebar-new-coupon {
  cursor: pointer;
  color: var(--primary);
  font-weight: bold;
}

.cart-sidebar-coupon {
  width: 100%;
  /* display: flex; */
  position: relative;
}

.btn-apply-coupon {
  min-width: 100px;
  position: absolute;
  right: 0;
  top: 0;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.btn-edit-coupon {
  position: absolute;
  top: 12px;
  right: 115px;
  color: var(--primary);
  /* font-weight: bold; */
  cursor: pointer;
}

.btn-checkout {
  width: 100%;
}

/* SNSidebar */
.sn-sidebar-input {
  position: absolute;
  right: 0;
  top: 0;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

/* SNInput */
.sn-input-protection {
  color: gray;
}

.sn-button {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  height: 55px !important;
  min-width: 77px !important;
  max-width: 77px !important;
}

/* ModalCoupon */
.modal-coupon-header {
  font-weight: bold;
  font-size: 18px;
}

/* ModalOrderReady */
.check-mark {
  position: absolute;
  right: 10px;
  top: 8px;
}

/* CartItem */
.pwp-container {
  background-color: var(--primary);
  color: #ffff;
  text-align: center;
  padding: 5px 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: -1rem;
  font-size: 12px;
  cursor: pointer;
}

.pwp-container-disable {
  background-color: var(--white);
  color: #ffff;
  text-align: center;
  padding: 5px 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: -1rem;
  font-size: 12px;
  cursor: pointer;
  height: 28px;
}

.pwp-container:hover {
  background-color: var(--hover);
}

.delete-cart {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.delete-cart:hover {
  color: red;
}

.modal-delete-txt {
  font-weight: bold;
  color: grey;
}

.tax-address {
  position: relative;
}

.tax-spinner {
  position: absolute;
  right: 10px;
  top: 5px;
}

.address-list {
  display: flex;
  flex-direction: column;

  max-height: 500px;
  overflow-y: auto;
}

.address-card {
  border: 1px solid #646464;
  border-radius: 8px;
  padding: 14px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* CustomSearch */

.table-search-card {
  width: 100%;
  box-shadow: 0 20px 30px 0 rgb(0 0 0 / 6%);
  border-radius: 20px;
  background-color: #ffff;
}

.table-search-card tr {
  border-bottom: 1px solid #dcdcdc;
}

table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
}

th {
  background-color: #fee9dd;
}

th,
td {
  text-align: left;
  padding: 16px;
}

table tr th {
  white-space: nowrap;
}

.add-protect-btn {
  color: #ee6026;
  font-weight: 600;
  cursor: pointer;
}

.remove-protect-btn {
  color: #ff302a;
  font-weight: 600;
  cursor: pointer;
}

.text-md {
  font-size: 14px;
}

/* CustomerHistory */
.label-status {
  font-weight: 600;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
}

.label-status.red {
  background-color: #f2535b;
  color: #ffff;
}

.label-status.green {
  background-color: #00cc99;
  color: #ffff;
}

.pay-btn {
  color: #ee6026;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
}

.label-status.orange {
  background-color: #f7bb08;
  color: #ffff;
}

/* ModalQRInvoice */
.qr-container {
  padding: 15px 0 15px 0;
  max-width: 330px;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.url-container {
  display: flex;
  align-items: center;

  background-color: #f6f6f6;
  color: #000000;
  padding: 10px;
  border-radius: 5px;
}

.copy-icon {
  cursor: pointer;
  background-color: #ffff;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  max-width: 50px;
  max-height: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease-in-out;
}

.copy-icon:hover {
  background-color: #ee6026;
  color: #ffff;
}
